<template>
    <v-container>
        <form slot="leftContainer" autocomplete="nope" @submit.prevent="salesGenerate" class="my-2">
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>VENTAS REALIZADAS </b>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-row dense align="center">
                        <v-col v-if="[2,3].includes(user.id_role)" cols="12" sm="3">
                            <v-autocomplete
                                dense
                                v-model="form.idColaborador"
                                v-validate="'required'"
                                :items="_listaUser"
                                item-text="nombres"
                                item-value="id"
                                :error-messages="errors.collect('form.idColaborador')"
                                label="Colaborador"
                                data-vv-name="form.idColaborador"
                                data-vv-as="nombres"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="fechaFiltro"
                                        label="Fecha Inicio"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="fechaFiltro" no-title scrollable @input="menu = false"> </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-btn type="submit" :loading="loading" dark color="primary" block :disabled="!form.fecha">
                                <b>{{ 'BUSCAR' }}</b>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </form>
    </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'

import { format } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            fechaFiltro: format(new Date(), 'yyyy-MM-dd'),
            menu: false,
            loading: false,
            form: DefaultForm.formVentasRealizadas(),
            dialog: false
        }
    },

    computed: {
        ...mapState('sedes', ['sedes']),
        ...mapState('users', ['user', 'listaUser']),

        _listaUser() {
            const data = this.listaUser.filter((x) => {
                return x.status && (x.id_role == 6 || x.optionalRole?.isVendor)
            })
            return data
        },
    },

    methods: {
        async salesGenerate() {
            try {
                const sede = this.sedes.find((x) => x.id == this.user.idSede)
                const colaborador = this._listaUser.find((x) => x.id == this.form.idColaborador) ?? ''
                const nameColab= ![2,3].includes(this.user.id_role)? this.user.nombres +'' +this.user.apellidos : colaborador.nombres+''+colaborador.apellidos
                await this.$store.dispatch('reporte/reporteVentasGeneradas', {
                    idColaborador: ![2,3].includes(this.user.id_role) ? this.user.id : this.form.idColaborador,
                    fecha: this.fechaFiltro,
                    sede: sede.sede_name_id,
                    idSede:this.user.idSede,
                    colaborador: nameColab,
                    idBussines: this.user.idBussines
                })
            } catch (error) {
                this.loading = false
                console.error(error)
            }
        },
        async getListaCot({ state = true, numeroDocumento = null }) {
            //console.log('state ', state)
            this.loadingCotizacion = true
            await this.$store.dispatch('cotizaciones/getLista', { fechaFiltro: this.fechaFiltro, state, numeroDocumento })
            this.loadingCotizacion = false
            this.menu3 = false
        }
    },
    async created() {
        this.loadingCotizacion = true
        await Promise.all([this.getListaCot({ state: true })])

        this.loadingCotizacion = false
    }
}
</script>
